.scribble .scribble-area {
	position: relative;
	border-right: 1px solid #e7e7e7;
	border-bottom: 1px solid #e7e7e7;
	border-left: 1px solid #e7e7e7;
	border-radius: 5px;
}
.scribble.scribble-not-editable .scribble-area {
	border-top: 1px solid #e7e7e7;
}
.scribble .scribble-area .scribble-board {
	z-index: 3;
	position: absolute;
	top: 0;
	left: 0;
}
.scribble .scribble-area .scribble-video {
	z-index: 2;
	position: absolute;
	top: 0;
	left: 0;
	object-fit: fill;
	transform: scaleX(-1);
}
.scribble .scribble-area .scribble-background {
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;
}

.scribble .scribble-actions.navbar {
	margin-bottom: 0;
}
.scribble .btn.btn-circular {
	border-radius: 50%;
}
.scribble .btn.btn-fab {
	position: absolute;
	z-index: 4;
	bottom: 10px;
	right: 10px;
	width: 50px;
	height: 50px;
}
.scribble .btn.btn-fab > i.fa {
	margin-left: -5px;
	margin-top: 3px;
}
.scribble input[type=file].scribble-file-camera,
.scribble .scribble-composed
{
	display: none;
}

.scribble .scribble-pens > a,
.scribble .scribble-colors > a
{
	padding-left: 6px;
	padding-right: 6px;
}
