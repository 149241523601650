.ui-history {
}

.ui-history a {
	cursor: pointer;
}

.ui-history .dropdown-menu > li > a {
	padding: 3px 10px;
}

.ui-history .dropdown-menu > li > a > i {
	margin-right: 5px;
}

.ui-history .ui-history-item {
	position: relative;
}

.ui-history .ui-history-item .ui-history-meta {
	position: absolute;
	top: 5px;
	right: 8px;
	z-index: 100;
}

.ui-history .ui-history-item .ui-history-delete-post {
	display:inline-block;
	margin-right: 10px;
	cursor: pointer;
	visibility: hidden;
}

.ui-history .ui-history-item:hover .ui-history-delete-post{
	visibility: visible;
}

.ui-history .ui-history-item .ui-history-delete-post i {
	line-height: 24px;
}

.ui-history .ui-history-item .ui-history-timestamp {
	display: inline-block;
	color: #BBB;
}

.ui-history .ui-history-item .ui-history-tags {
	display: inline-block;
}

.ui-history .ui-history-item .ui-history-tags .ui-history-tag {
	display: inline;
	padding: .2em .6em .3em;
	font-size: 75%;
	font-weight: 700;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: .25em;
	background-color: #5bc0de;
}

.ui-history .ui-history-item .ui-history-tags .ui-history-tag + .ui-history-tag {
	margin-left: 2px;
}

.ui-history [ng-quill-editor] {
	display: block;
}

.ui-history ng-quill-toolbar a.btn.btn-success {
	color: #fff;
}

.ui-history ng-quill-toolbar a.btn + a.btn {
	margin-left: 5px;
}

.ui-history .form-group > .col-sm-12 {
	margin-bottom: 50px;
}

.ui-history .form-group > .col-sm-12,
.ui-history .ql-container {
	height: 250px;
}

.ui-history-company {
	margin: 3px 15px;
	color: #BBB;
}

/* type=user.comment {{{ */
.ui-history .ui-history-user-comment {
	position: relative;
	min-height: 60px;
	padding-left: 60px;
	margin: 19px 0;
}

.ui-history .ui-history-user-comment > .ui-history-user-comment-user {
	width: 50px;
	height: 50px;
	border-radius: 3px;
	float: left;
	margin-left: -60px;
}

.ui-history .ui-history-user-comment > .ui-history-user-comment-main {
	position: relative;
	border: 1px solid #CFD8DC;
	border-radius: 0;
}

.ui-history .ui-history-user-comment > .ui-history-user-comment-main > .ui-history-user-comment-header {
	margin: 12px 15px;
	color: #666;
	border-radius: 3px;
}

.ui-history .ui-history-user-comment > .ui-history-user-comment-main > .ui-history-user-comment-body {
	position: relative;
	margin: 12px 15px;
	overflow: hidden;
	border-radius: 3px;
}

/* Speach arrows {{{ */
.ui-history .ui-history-user-comment > .ui-history-user-comment-main::before,
.ui-history .ui-history-user-comment > .ui-history-user-comment-main::after {
	content: "";
	position: absolute;
	top: 11px;
	left: -16px;
	display: block;
	width: 0;
	height: 0;
	pointer-events: none;
	border-color: transparent;
	border-style: solid solid outset;
}

.ui-history .ui-history-user-comment > .ui-history-user-comment-main::before {
	border-width: 8px;
	border-right-color: #CFD8DC;
}

.ui-history .ui-history-user-comment > .ui-history-user-comment-main::after {
	margin-top: 1px;
	margin-left: 2px;
	border-width: 7px;
	border-right-color: #fff;
}
/* }}} */
/* }}} */
/* type=user.change {{{ */
.ui-history .ui-history-user-change {
	position: relative;
	min-height: 60px;
	padding-left: 60px;
	margin: 19px 0;
}

.ui-history .ui-history-user-change > .ui-history-user-change-user {
	width: 50px;
	height: 50px;
	border-radius: 3px;
	float: left;
	margin-left: -60px;
}

.ui-history .ui-history-user-change > .ui-history-user-change-main {
	position: relative;
	border-radius: 0;
	padding-top: 15px;
}
/* }}} */
/* type=user.upload {{{ */
.ui-history .ui-history-user-upload {
	position: relative;
	min-height: 60px;
	padding-left: 60px;
	margin: 19px 0;
}

.ui-history .ui-history-user-upload > .ui-history-user-upload-user {
	width: 50px;
	height: 50px;
	border-radius: 3px;
	float: left;
	margin-left: -60px;
}

.ui-history .ui-history-user-upload > .ui-history-user-upload-main {
	position: relative;
	border-radius: 0;
	padding-top: 15px;
}
/* }}} */
/* type=user.status {{{ */
.ui-history .ui-history-user-status {
	position: relative;
	min-height: 60px;
	padding-left: 60px;
	margin: 19px 0;
}

.ui-history .ui-history-user-status > .ui-history-user-status-user {
	width: 50px;
	height: 50px;
	border-radius: 3px;
	float: left;
	margin-left: -60px;
}

.ui-history .ui-history-user-status > .ui-history-user-status-main {
	position: relative;
	border-radius: 0;
	padding-top: 15px;
}
/* }}} */
/* type=system.change {{{ */
.ui-history .ui-history-system-change {
	margin-left: 60px;
}
/* }}} */
/* type=system.status {{{ */
.ui-history .ui-history-system-status {
	margin-left: 60px;
}
/* }}} */
/* type=unknown {{{ */
.ui-history .ui-history-unknown {
	margin-left: 60px;
	margin-bottom: 20px;
	padding: 15px;
	color: #a94442;
	background-color: #f2dede;
	border: 1px solid #ebccd1;
	border-radius: 4px;
}
/* }}} */
/* Latest Post {{{*/
.ui-history.ui-history-latest .ui-history-user-comment > .ui-history-user-comment-main::before,
.ui-history.ui-history-latest .ui-history-user-comment > .ui-history-user-comment-main::after {
	content: none;
}
.ui-history-latest-user {
	color: #BBB;
}
.ui-history.ui-history-latest .ui-history-item {
	border-bottom: none;
}
.ui-history.ui-history-latest .ui-history-item .ui-history-meta {
	text-align: right;
	color: #BBB;
}
/* }}} */
/* Print mode {{{ */
@media print {
	/* Hide interactive components when printing */
	.ui-history ui-history-editor {
		display: none;
	}

	/* Override Bootstrap link display */
	.ui-history a[href]:after {
		display: none;
	}
}
/* }}} */
