/* BUGFIX: Force buttons to be a consistant height when no icon is actually visible */
mg-choice-buttons .fa.fa-fw {
	height: 1em;
}

@media print {
	/* Disable dropdown icons when printing */
	mg-choice-dropdown .selectize-input::after {
		display: none !important;
	}
}

.ui-select-placeholder {
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: calc(100% - 15px);
}
/* mgContainerRowLarge {{{ */
.row.mgContainerRowLarge {
	font-size: 200%;
}

.row.mgContainerRowLarge input.form-control {
	height: 48px;
}
/* }}} */

mg-form .form-group {
	margin-bottom: 10px;
}

/* Generic helper classes {{{ */
mg-form-editor .text-nowrap {
	white-space: nowrap;
}

mg-form-editor .text-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
}

/* }}} */

/* Add widget modal {{{ */
/* Tidy up the left hand size widget filter pills {{{ */
mg-form-editor #modal-mgFormEditor-add .nav.nav-pills li:not(.active) a {
	color: #333;
}

mg-form-editor #modal-mgFormEditor-add .nav.nav-pills li.active i> a {
	color: #FFF;
}

mg-form-editor #modal-mgFormEditor-add .nav.nav-pills > a > i {
	margin-right: 5px;
}
/* }}} */
/* Tidy up the main widget selection boxes {{{ */
mg-form-editor #modal-mgFormEditor-add .widget-item {
	margin-bottom: 5px;
}
/* }}}
/* }}} */

/* Hover mask {{{ */
@keyframes mgFormMoveMaskEditor { from { background-position: 0; } to { background-position: 140px; } }

mg-form-editor .mgFormEditor-mask {
	position: fixed;
	z-index: 100;
	border-radius: 5px;

	/* Start offscreen */
	left: -10000px;
	top: -10000px;
	width: 0px;
	height: 0px;
}

mg-form-editor .mgFormEditor-mask.mgFormEditor-mask-editor  {
	border: 3px solid #5662a8;
}

mg-form-editor .mgFormEditor-mask.mgFormEditor-mask-inserter  {
	border: 3px solid #56a628;
}

mg-form-editor .mgFormEditor-mask.mgFormEditor-mask-editor > .mgFormEditor-mask-background {
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	animation: mgFormMoveMaskEditor 5s linear 0s infinite;
	opacity: 0.3;
	background: repeating-linear-gradient(
		45deg,
		#606dbc,
		#606dbc 10px,
		#5662a8 10px,
		#5662a8 20px
	);
	background-size: 140px 140px;
}

mg-form-editor .mgFormEditor-mask.mgFormEditor-mask-inserter > .mgFormEditor-mask-background {
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0.3;
	background: repeating-radial-gradient(
		circle,
		#60bc6d,
		#60bc6d 40px,
		#56a862 40px,
		#56a862 80px
	);
}

mg-form-editor .mgFormEditor-mask .mgFormEditor-mask-buttons {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

mg-form-editor .mgFormEditor-mask .mgFormEditor-mask-buttons > .mgFormEditor-mask-buttons-left {
	position: absolute;
	top: calc(50% - 16px);
	left: 10px;
}

mg-form-editor .mgFormEditor-mask .mgFormEditor-mask-buttons > .mgFormEditor-mask-buttons-right {
	position: absolute;
	top: calc(50% - 16px);
	right: 10px;
}

mg-form-editor .mgFormEditor-mask .mgFormEditor-mask-buttons a {
	padding: 8px 12px !important;
}

mg-form-editor .mgFormEditor-mask .mgFormEditor-mask-buttons .mgFormEditor-mask-handle {
	cursor: move;
}

mg-form-editor .mgFormEditor-mask .mgFormEditor-mask-buttons .dropdown-menu a {
	padding: 3px 10px;
}
/* }}} */

mg-list td {
	padding: 0 !important;
}

mg-list td input.form-control {
	border: none;
}

mg-list td .btn {
	height: 35px;
}

mg-list td .btn > i {
	padding-top: 4px;
}

mg-list td:first-child.text-center {
	vertical-align: middle;
}

/* Hide the spin button in mgNumber controls */
mg-number input[type=number]::-webkit-outer-spin-button,
mg-number input[type=number]::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

mg-placeholder > div {
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
}

/* Style: placeholder-box {{{ */
mg-placeholder > .placeholder-box {
	border: 1px solid #000;
	background:
		linear-gradient(to top left,
			rgba(0,0,0,0) 0%,
			rgba(0,0,0,0) calc(50% - 0.8px),
			rgba(0,0,0,1) 50%,
			rgba(0,0,0,0) calc(50% + 0.8px),
			rgba(0,0,0,0) 100%),
		linear-gradient(to top right,
			rgba(0,0,0,0) 0%,
			rgba(0,0,0,0) calc(50% - 0.8px),
			rgba(0,0,0,1) 50%,
			rgba(0,0,0,0) calc(50% + 0.8px),
			rgba(0,0,0,0) 100%);
}

mg-placeholder > .placeholder-box > .placeholder-text {
	background: #FFF;
	border: 1px solid #000;
	padding: 10px 15px;
}
/* }}} */

/* Style: placeholder-box {{{ */
mg-placeholder > .placeholder-construction {
	background: repeating-linear-gradient(45deg, #f1c40f, #f1c40f 10px, #000 10px, #000 20px);
}

mg-placeholder > .placeholder-construction > .placeholder-text {
	color: #FFF;
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
/* }}} */

mg-seperator > hr {
	margin-top: 0px;
	margin-bottom: 10px;
}

mg-signature .form-control-static {
	display: block;
	width: 250px;
	height: 50px;
	border: 1px solid #e9e9e9;
	background: #FFF;
}

mg-signature .form-control-static::after {
	content: '';
	display: block;
	position: relative;
	top: 30px;
	margin-left: auto;
	margin-right: auto;
	width: 80%;
	border-bottom: 1px dashed #e9e9e9;
}

mg-signature .btn.btn-fab {
	position: absolute;
	left: 0;
	bottom: 10px;
}

mg-signature .btn.btn-fab > i.fa {
	margin-left: -2px;
	margin-top: 6px;
}

mg-table td {
	padding: 0 !important;
}

mg-table td > .btn {
	height: 35px;
}

mg-table td > mg-container > .form-group {
	margin: 0 !important;
}

mg-table td > mg-container > .form-group .col-sm-12 {
	padding: 0 !important;
}

mg-table td > mg-container > .form-group .col-sm-12 .form-control {
	border: none;
}

@media print {
	/* Toggle selection needs to be more visible in monochrome prints */
	mg-toggle .btn.btn-default {
		background: #398439 !important;
		color: #fff !important;
	}
}
